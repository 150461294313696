
.burn-container{
    padding-top: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: #F6F6F6;
    
    width: calc(100% / 3 * 2);
    min-height:fit-content;
}
.carousel-header{
    margin-left: 50px;
    font-size:20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.selected{
    background-color: #E3E3E3;
    font-size: 12px;
    
    align-items: center;
    text-align: center;
    border-radius: 3px;
    margin-top: 3px;
    margin-left: 5px;
    padding: 3px 10px;
}

.label{
    margin-right: 10px;
}
.card{
    display:flex;
}
.burn-button{
    display:flex;
    justify-content: right;
    padding-top:20px;
    margin-right: 55px;
    /* margin-right:100px; */
}
.footer{
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 0px 0px;
}

.carousel-demo .product-item .product-item-content {
    border: 1px solid #808080;
    border-radius: 3px;
    margin: 0.2rem;
    text-align: center;
    padding: 1rem 0;

}

.carousel-demo .product-item .product-image {
    width: 80%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
}

.p-carousel-indicator.p-highlight button{
    background-color:#3333ff !important;
}
.pi-chevron-left:before {
    content: "\e900";
    color:#3333ff ;
}

.pi-chevron-right:before{
    content: "\e901";
    color:#3333ff;
}
.p-inputswitch .p-inputswitch-slider {
    background: #E3E3E3;
}
.p-inputswitch .p-inputswitch-slider:hover {
    background: #771d1d;
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
    background: #E3E3E3;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
    background: #3333ff;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
    background: #E3E3E3;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
    background-color: #E3E3E3;
    width: 2rem;
    height: 0.5rem;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
    border-radius: 0;
}

.p-carousel-container{
    padding-top: 10vh;
}
.cont{
    width:65vw;
}
.burn{
    color:#fff; 
    background: #3333ff;
    padding: 10px 20px;
}

input[type="checkbox"] {
    cursor: pointer;   
}


@media screen and (max-height: 700px) {

    .p-carousel-container{
        padding-top: 20px;
    }
    .burn-button{
        padding-top:5px;
    }

}

@media screen and (max-width: 960px) {
    .burn-container{
        height: auto ;
        width: 100%;
    }
    .footer{
        text-align: center;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 0px 0px;
    }
    .burn-button{
        display:flex;
        justify-content: center;
        padding:20px 0px;
        margin-left: 50px;
        
        /* margin-right:100px; */
    }
    .carousel-header{
        display: flex;
        justify-content: center;
        padding: 10px 20px 0px 20px;
        padding-right:50px;
    }
    .p-carousel-container{
        padding-top: 20px;
    }
    .cont{
        width:85vw;
    }
}

@media screen and (max-width: 480px) {
    .p-carousel-content{
        align-items:center;
    }
    .p-carousel-container{
        padding-top: 20px;
    }
}

