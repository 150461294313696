
.info-container{
    width: calc(100% / 3 * 1);
    background-color: #E3E3E3 ;
    display:flex;
    justify-content: center;
    overflow: auto;
}

.info{
    padding-left: 50px;
    padding-top: 14px;
    display: flex;
    flex-direction: column;
    
    
}
.title{
    font-size: 20px;
    font-weight:500;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

}
.info-section{
    padding-top: calc(1vw + 1vw);
    margin-right:1rem;
    display: flex;
    flex-direction: row;
    margin-block: min(2vh, 5vh);
}
.circle{
    width:42px;
    height: 42px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%23808080FF' stroke-width='4' stroke-dasharray='1%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 100px;
    padding:10px 17px
}
.text{
    position: relative;
    padding: 0px 4rem 0px 50px;
    font-size: calc(13px + 0.1vw);
}

.button-container{
    display: flex;
    justify-content: center;
    margin-top: min(30px, 20%);
}
button.p-button.p-component.button{
    /* width:334px; */
    color: #808080 !important;
    border:none !important;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23808080FF' stroke-width='3' stroke-dasharray='1%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px; 
    background-color: #e3e3e300;
}
button.p-button.p-component.button:enabled:hover{
    /* width:334px; */
    color: #808080 !important;
    border:none !important;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23808080FF' stroke-width='3' stroke-dasharray='1%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px; 
    background-color: #e3e3e300;
}
button.p-button.p-component.button:active{
    /* width:334px; */
    color: #808080 !important;
    border:none !important;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23808080FF' stroke-width='3' stroke-dasharray='1%2c 9' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px; 
    background-color: #e3e3e300;
}

@media screen and (max-width: 960px) {
    .info-container{
        width: 100%;
        background-color: #E3E3E3 ;
        display:flex;
        padding: 20px 0px 50px 0px;
        overflow:unset;
    }

    .info{
        padding-left: 10px;
        padding-top: 14px;
        
    
    }
    .circle{
        display: none;
    }
    .title{
        
        max-inline-size: 100px;
        overflow-wrap: break-word;
    }
    .text{
        padding: 0px 20px 0px 20px;
        font-size: 15px;
    }
    .button{
        color: aqua;
    }


}