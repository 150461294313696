
.header {
    background-color: #e0e0e0;
    min-height:150px;
    display: flex;
    justify-content: space-between;

    align-items: center;
    padding:0px 0px;

    /* box-shadow: 0px 6px 9px 0px rgba(66, 68, 90, 0.2); */
    z-index: 999;
}
.logo{
    font-size: 24px ;
    color:#fff;
    width: calc(100% / 3 * 1);
    background-color: #E3E3E3 ;
    height:150px;
    padding:5px 13px;
    
    
}

.account{
    color:#fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px ;
    width: calc(100% / 3 * 2);
    background-color: #F6F6F6;
    height:150px;
    padding:50px 50px;
}


.wallet-address{
    padding: 0px 20px;
    color:rgb(32, 32, 32);
}
.wallet-balance{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.p-button:disabled.p-button-text {
    background-color: #3333ff00 !important;
    color: #070707 !important;
    border:1px solid #808080 !important;
}

@media screen and (max-width: 960px) {
    .logo{
        background-color: #F6F6F6 ;
        
    }
}