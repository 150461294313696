@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;700&display=swap');  

*{
    font-family: 'Antonio', sans-serif;
    /* font-family: 'Open Sans', sans-serif; */
    
}
body{
  /* background-color: rgb(228, 228, 228); */
}
.App {
  text-align: center;
  background-color: rgb(228, 228, 228);
  min-height: 100vh;
  display:flex;
  flex-direction: row;
  height: auto;
}

.container{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 150px);
}
.notConnected{
  background-color: #F6F6F6;
  height: calc(100vh - 150px);
  width: calc(100% / 3 * 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.not-connected-msg{
  margin-top: -200px;
}
/* .container:nth-child(1) {
  
}

.container:nth-child(2) {
  width: calc(100% / 3 * 2);
} */
@keyframes p-progress-spinner-color {
  100%,
  0% {
      stroke: #3333ff ;
  }
  
}

@media screen and (max-width: 960px) {
  .container{
    display: flex;
    flex-direction: column;

  }
  .notConnected{
    width: 100% ;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0px;
    
  }
}